import {Account, CreateAccountParams, CreateAccountRes, CreateWebLoginParams, CreateWebLoginRes} from '../../shared/users/user.types';
import {AccountFinderModal} from '../../shared/authentication/AccountFinderModal/AccountFinderModal';
import {AccountInfo} from '../../server/account-info/account-info.class';
import {appComponent} from '../app.component';
import {FetchService} from '../fetch/fetch.service';
import {ImpError} from '../imp-error/imp-error.class';
import {LoginModal} from '../../shared/authentication/login/LoginModal';
import {ProgressOverlay} from '../components/ProgressOverlay';
import {ReactClientService} from '../react/react-client.service';
import {SessionStorageService} from '../session-storage/session-storage.service';
import {UserStateService} from './user-state.service';
import {User} from '../../shared/users/user.class';
import {BodyScroll} from '../../shared/ui/ScrollHandler';

const selectAccountFormTemplate = require('./_select-account-form.ejs');

export class UsersService {
    private readonly _currentUser: User = null;
    private readonly _reactClientService: ReactClientService;

    constructor(
        private _fetchService: FetchService,
        private _reactModalElement: HTMLElement,
        private _sessionStorageService: SessionStorageService,
        private _userData: User,
        private _userStateService: UserStateService,
    ) {
        if (this._userData) {
            this._currentUser = new User(this._userData);
        }
        this._reactClientService = new ReactClientService();
    }

    /**
     * Creates account in term
     * @param createAccountParams - Account registration data
     */
    public createAccount(createAccountParams: CreateAccountParams) {
        return this._fetchService.post<CreateAccountRes>(`/api/users/createAccount`, createAccountParams);
    }

    /**
     * Submits request for a web login on an existing account
     * @param createWebLoginParams
     */
    public createWebLogin(createWebLoginParams: CreateWebLoginParams) {
        return this._fetchService.post<CreateWebLoginRes>(`/api/users/createWebLogin`, createWebLoginParams);
    }

    /**
     * Drills into specified account
     * @param accountToDrill
     */
    public drillAccount(accountToDrill: string) {
        return new Promise<boolean>((resolve, reject) => {
            this._fetchService
                .get<boolean>(`/api/users/drillAccount/${encodeURIComponent(accountToDrill)}`)
                .then((drillAccountRes) => {
                    // Clear session storage (for account number changes)
                    this._sessionStorageService.clear();

                    // Return success response
                    resolve(drillAccountRes);
                })
                .catch((drillAccountErr: ImpError) => {
                    reject(drillAccountErr);
                });
        });
    }

    /**
     * Returns detailed account info for requested account
     * @param account - Account number to get info for
     */
    public getAccountInfo(account: string) {
        return this._fetchService.get<AccountInfo>(`/api/users/getAccountInfo?account=${account}`);
    }

    /**
     * Return list of accounts available to the user
     */
    public getAccountList() {
        return this._fetchService.get<Account[]>(`/api/users/getAccountList`);
    }

    /**
     * Returns currentUser
     */
    public getCurrentUser(): User {
        return this._currentUser;
    }

    /**
     * Performs logon for user
     * @param componentName - Component triggering the logon
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @param returnURL - Url to return to following logon
     */
    public logon(componentName: string, onPopUpCallback?: () => void, returnURL?: string) {
        // Record logon action
        this._userStateService.recordPendingAction(componentName, `logon`);

        // If not logged in, showLoginPopUp
        if (!this._currentUser.isLoggedIn()) {
            this.showLoginPopUp(onPopUpCallback, returnURL);
            return;
        }

        // If not activeAccount, present AccountPickerComponent in select mode
        if (!this._currentUser.activeAccount) {
            this.showAccountPicker(true, onPopUpCallback, returnURL);
            return;
        }

        // Once logon complete, clear pending action
        this._userStateService.clearPendingAction();
    }

    /**
     * Posts to /select-account for the provided account information
     * @param accountNumber - Account number of selected account
     * @param contactID - ContactID of selected account
     * @param redirectUrl - Optional redirect after selection
     * @param accountEntry - Manually entered account number
     */
    public selectAccount(accountNumber?: string, contactID?: string, redirectUrl?: string, accountEntry?: string) {
        // Clear session storage (for account number changes)
        this._sessionStorageService.clear();

        // Build and submit selectAccountForm
        const selectAccountForm = $(
            selectAccountFormTemplate({
                accountEntry,
                accountNumber,
                contactID,
                csrfToken: appComponent.csrfToken,
                redirectUrl,
            }),
        );
        $(`body`).append(selectAccountForm);
        selectAccountForm.submit();
    }

    /**
     * Specifies activeCatalog for the user
     * @param catalogId - CatalogId to make active
     */
    public selectCatalog(catalogId: string) {
        return this._fetchService.post<string>(`/api/users/selectCatalog`, {catalogId}, true);
    }

    /**
     * Submit onboarding request
     * @param onboardingParams - onboardingParams
     */
    public onboardUser(onboardingParams: any) {
        return this._fetchService.post<string>(`/api/submitOnboarding`, onboardingParams, true);
    }

    /**
     * Request a verification resend
     * @param email
     */
    public resendVerification(email: string) {
        return this._fetchService.get(`/api/users/resendVerification?email=${encodeURIComponent(email)}`, undefined, true);
    }

    /**
     * Shows AccountPickerComponent to user
     * @param selectAccountMode - If selectAccount mode should be used
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @param returnUrl - Url to return to following login or account selection
     */
    public showAccountPicker(selectAccountMode: boolean, onPopUpCallback?: () => void, returnUrl?: string) {
        // Call onPopUpCallback if exists (close calling UI)
        if (onPopUpCallback) {
            onPopUpCallback();
        }

        // Show AccountPickerComponent
        ProgressOverlay.deactivate();
        const [root] = this._reactClientService.appendComponent(
            AccountFinderModal,
            {
                accountOnlyMode: true,
                onHide: () => {
                    root.unmount();
                },
                returnUrl,
                selectAccountMode,
                show: true,
                user: this._currentUser,
            },
            this._reactModalElement,
        );
    }

    /**
     * Shows LoginComponent to user
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @param returnURL - Url to return to following login or account selection
     * @param reauthenticate - If re-authentication messaging should be shown
     */
    public showLoginPopUp(onPopUpCallback?: () => void, returnURL?: string, reauthenticate?: boolean) {
        // Call onPopUpCallback if exists (close calling UI)
        if (onPopUpCallback) {
            onPopUpCallback();
        }

        // Determine url param
        let url = `${location.pathname}${location.search}`;
        if (returnURL) {
            url = returnURL;
        }

        // Show LoginComponent
        ProgressOverlay.deactivate();
        const [root] = this._reactClientService.appendComponent(
            LoginModal,
            {
                onClose: () => {
                    root.unmount();
                    BodyScroll(true);
                },
                reauthenticate,
                returnUrl: url,
                show: true,
                user: this._currentUser,
            },
            this._reactModalElement,
        );
    }
}
